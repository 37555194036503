<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { cpfCnpj } from '@/utils/validator'
import AuthWrapper from '@/views/auth/components/AuthWrapper'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import InputField from '@/components/InputField'
import FormResponseMessage from '@/components/FormResponseMessage'

export default {
  name: 'RecoverPassword',

  components: {
    AuthWrapper,
    Action,
    Icon,
    InputField,
    FormResponseMessage,
  },

  data () {
    return {
      formData: {
        user: null,
      },
      loading: false,
      response: {
        message: null,
        type: null,
      },
    }
  },

  validations: {
    formData: {
      user: {
        required,
        cpfCnpj,
      },
    },
  },

  methods: {
    ...mapActions('auth', ['recoverPassword']),

    handleSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      this.loading = true
      this.recoverPassword(this.formData).then(() => {
        this.loading = false
        this.response.message = 'Código enviado com sucesso! <br/>Verifique sua caixa de entrada de e-mail e spam.'
        this.response.type = 'success'
      }).catch(() => {
        this.loading = false
        this.formData.user = null
        this.response.message = 'CPF/CNPJ não encontrado. <br/>Verifique as informações fornecidas.'
        this.response.type = 'error'
      })
    },
  },
}
</script>

<template>
  <auth-wrapper>
    <h1 :class="$classes['title']">Recuperação de senha</h1>
    <p :class="$classes['description']">Enviaremos um código de recuperação ao e-mail vinculado a sua conta.</p>
    <form @submit.prevent="handleSubmit" v-if="!response.type !== 'success'">
      <input-field
        v-model="formData.user"
        label="CPF/CNPJ"
        placeholder="Escreva aqui"
        :mask="['###.###.###-##', '##.###.###/####-##']"
        :validation="$v.formData.user"
      />
      <form-response-message
        v-if="response.message"
        :message="response.message"
        :type="response.type"
      />
      <div :class="$classes['form-actions']">
        <action
          full-width
          variant="contained"
          color="primary"
          type="submit"
          :loading="loading"
          :disable="loading"
        >
          Enviar código
        </action>
      </div>
    </form>
    <action
      variant="flat"
      color="secondary"
      uppercase
      :to="{ name: 'auth.login' }"
    >
      <icon name="long-arrow-alt-left" btnIcon/>
      Voltar
    </action>
  </auth-wrapper>
</template>

<style module>
.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 15px;
  color: rgba(41, 41, 41, 0.8);
}

.description {
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-bottom: 25px;
  color: rgba(41, 41, 41, 0.8);
}

.form-actions {
  margin-top: 30px;
  margin-bottom: 45px;
}
</style>
